/*global Cardinal, a*/
/*eslint no-undef: "error"*/

/** The create token flow handles the process of getting a token that is ready to be charged from a flex form*/
import { Deferred } from '../../../../common/utils/Deferred'
import api from '../../../../common/api'
import config from '../../../../../config'
import RemoteAuthHandler from '../../auth/RemoteAuthHandler'
import { log } from '../../../../common/utils/logger'

// @ts-ignore
export default class CreateTokenFlow {
  constructor(
    publicKey,
    chargeIntentUUID,
    threeDSecureVersion,
    vgsSecureForm,
    cardBrand,
    cardLast4,
    extraRequestData
  ) {
    this.deferred = new Deferred()
    this.chargeIntentUUID = chargeIntentUUID
    this.createTokenData = {
      brand: brandMap[cardBrand] || cardBrand,
      last4: cardLast4,
      key: publicKey,
      ...extraRequestData,
    }
    this.vgsSecureForm = vgsSecureForm
    this.threeDSecureVersion = threeDSecureVersion
    this.#runFlow()
  }

  /** run the flow and get a charge token for the given card details*/
  #runFlow = () => {
    log({
      message: `run_flow_start`,
    })
    return this.#tokenizeCardData()
      .then((cardData) => {
        return this.#createTokenizedSource(cardData)
      })
      .then(({ chargeIntent, cardNumber }) => {
        return this.#initCardinal(chargeIntent, cardNumber)
      })
      .then(({ chargeIntent, cardNumber }) => {
        return this.#processBin(chargeIntent, cardNumber)
      })
      .then((chargeIntent) => this.#maybeDoAuth(chargeIntent))
      .then((chargeIntent) => ({
        id: chargeIntent.tokenID,
        sourceType: chargeIntent.sourceType,
        source: chargeIntent.source,
        status: chargeIntent.status,
        metadata: chargeIntent.metadata,
      }))
      .then(this.deferred.resolve)
      .catch(this.deferred.reject)
  }

  /** Send a request to vgs with our card data that will return it in tokenized form */
  #tokenizeCardData = () =>
    new Promise((resolve, reject) => {
      this.vgsSecureForm.submit(
        '/post',
        {
          serializer: 'deep',
        },
        (status, response) => {
          const { cardNumber, cardCvv, cardExpiry } = response.json
          resolve({ cardNumber, cardCvv, cardExpiry })
        },
        (errors) => {
          log({
            message: `tokenize_card_data_error`,
            error: errors,
          })
          reject({
            errorType: 'vgs_tokenize_error',
            message: 'Please fix field related errors before submitting.',
            fieldErrors: errors,
          })
        }
      )
    })

  #initCardinal = (chargeIntent, cardNumber) => {
    return new Promise((resolve, reject) => {
      if (
        chargeIntent.threeDSecureVersion > 1 &&
        typeof Cardinal === 'undefined'
      ) {
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = config.cardinal.URL
        script.addEventListener(
          'load',
          () => {
            Cardinal.on('payments.setupComplete', function () {
              Cardinal.off('payments.setupComplete')
              resolve({ chargeIntent, cardNumber })
            })
            Cardinal.setup('init', {
              jwt: chargeIntent.jwt,
            })
          },
          false
        )
        script.addEventListener('error', () => reject(script), false)
        document.body.appendChild(script)
      } else {
        resolve({ chargeIntent, cardNumber })
      }
    })
  }

  #processBin = (chargeIntent, cardNumber) => {
    return new Promise((resolve, reject) => {
      if (chargeIntent.threeDSecureVersion > 1) {
        Cardinal.trigger('bin.process', cardNumber.substring(0, 6))
          .then(function () {
            resolve(chargeIntent)
          })
          .catch(function (error) {
            reject({
              errorType: 'Cardinal',
              message: 'Bin Processing failed',
              fieldErrors: error,
            })
          })
      } else {
        resolve(chargeIntent)
      }
    })
  }

  /** Submit a request with our tokenized card data, and any extra information we have to core, and use those details to process a payment*/
  #createTokenizedSource = ({ cardNumber, cardCvv, cardExpiry }) => {
    const requestBody = {
      ...this.createTokenData,
      cardNumber,
      cardCvv,
      cardExpiry,
      chargeIntentUUID: this.chargeIntentUUID,
      type: 'card',
    }

    log({
      message: `run_flow_create_tokenized_source`,
    })

    return api(
      `${config.thrive.BACKEND_URL}/payments/api/internal/v1/sources/`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
      }
    ).then((response) => {
      const chargeIntent = response?.data?.chargeIntent
      if ((response?.status || 200) !== 200 || !chargeIntent) {
        log({
          message: 'error_extract_charge_intent',
          info:
            response?.message || 'Something went wrong, please contact support',
          data: response?.data,
          status: response?.status,
        })
        throw {
          errorType: 'source_error',
          message:
            response?.message || 'Something went wrong, please contact support',
          data: response?.data,
          status: response?.status,
        }
      } else {
        return { chargeIntent, cardNumber }
      }
    })
  }

  /** if our charge intent requires us to process authentication, we will need to do that now, otherwise we are done*/
  #maybeDoAuth = (chargeIntent) => {
    log({
      message: `run_flow_maybe_do_auth`,
    })
    if (
      chargeIntent.status === 'registered' &&
      chargeIntent.authenticationPageUrl
    ) {
      log({
        message: 'auth_reg_perform_auth',
      })
      return RemoteAuthHandler.routeAuth(
        chargeIntent,
        chargeIntent.authenticationPageUrl
      )
        .catch((result) => {
          log({
            message: 'error_maybe_do_auth',
            info: result.failureMessage,
          })
          throw {
            message: result.failureMessage,
          }
        })
        .then(this.#maybeDoAuth)
    }
    log({ message: 'post_auth' })
    return chargeIntent
  }

  promise = () => {
    return this.deferred.promise
  }
}

/** this can be used to remap the brand names we get from vgs to ones that better suite our system*/
const brandMap = {
  mastercard: 'master',
}
