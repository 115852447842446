import * as React from 'react'
import CheckoutConfiguration from '../models/CheckoutConfiguration'
import PopupCardView from './components/PopupCardView'
import { useEffect, useState } from 'react'
// @ts-ignore
import YocoSDK from '../../YocoSDK'
import Money from '../../../common/utils/Money'
// @ts-ignore
import { log } from '../../../common/utils/logger'

interface Props {
  /** The configuration for this popup component*/
  configuration: CheckoutConfiguration
  /**
   * We allow an error message to be passed into the component if one needs to be enforced.
   * This is especially useful for visually testing the component in storybook.
   */
  errorMessage?: string
}

const Popup = ({ configuration, ...props }: Props) => {
  const onCancel = () => {
    //@ts-ignore
    window.onCancel()
  }

  // @ts-ignore
  const onSuccess = (token) => {
    // @ts-ignore
    window.onSuccess(token)
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [dropin, setDropin] = useState(undefined)
  const [dropinReady, setDropinReady] = useState(false)
  const [shake, setShake] = useState(false)

  useEffect(() => {
    const dropin = new YocoSDK(configuration).dropin({
      ...configuration,
      layout: 'basic',
    })

    dropin.on(YocoSDK.Events.VALIDITY_CHANGE, () => {
      // We want to set our error message to a validation error message
      const errorMessage = dropin.validationErrorMessage()
      const showErrorMessage = errorMessage?.trim() && true
      setShowErrorMessage(showErrorMessage)
      showErrorMessage && setErrorMessage(errorMessage)
      setIsValid(dropin.isValid())
    })

    setTimeout(() => dropin.mount('#drop-in'), 1)
    setDropin(dropin)

    dropin.on(YocoSDK.Events.READY, () => {
      // When the dropin is ready we maybe want to drive the showing and hiding based on this above everything else we doing
      setDropinReady(true)
    })

    // We also want to make sure there is a max time before the popup shows so that the user can
    // interact with things like the close button if they need to
    setTimeout(() => {
      setDropinReady(true)
    }, 8000)
  }, [])

  /** Handle the user pressing the submit payment button*/
  const onSubmit = () => {
    log({
      message: 'popup_customer_clicked_pay',
    })

    setShowErrorMessage(false)
    setIsSubmitting(true)
    // @ts-ignore
    dropin
      .createToken()
      .then((result: any) => {
        setIsSubmitting(false)
        if (result.error) {
          log({
            error: result.error,
            message: 'payment_processed_error',
          })

          setErrorMessage(result.error.message)
          setShowErrorMessage(true)
          setShake(true)
          setTimeout(() => setShake(false), 200)
        } else {
          log({
            paymentProcessedData: result,
            message: 'payment_processed_success',
          })
          // otherwise we have succeeded, so lets pass the token back
          onSuccess(result)
        }
      })
      .catch((result: any) => {
        setIsSubmitting(false)
        log({
          exception: result,
          message: 'payment_processed_error',
        })
      })
  }

  return (
    <PopupCardView
      amount={new Money(configuration.amountInCents, configuration.currency)}
      dropInId="drop-in"
      popupTitle={configuration.name}
      popupImage={configuration.image}
      popupDescription={configuration.description}
      onCancel={onCancel}
      errorMessage={errorMessage}
      showErrorMessage={showErrorMessage}
      isValid={isValid}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      dropinReady={dropinReady}
      shake={shake}
    />
  )
}

export default Popup
