import React from 'react'
import '../../BaseCard/BaseCardView.scss'
import BaseCardView from '../../BaseCard/BaseCardView'

/**
 * The plain card view is laid out but has no formatting, we can add formatting to it later using the provided classes.
 * This is our most basic form structure and the others leverage this for their layout.
 */
const PlainCard = ({ formInfo, showCvvTooltip = true }) => {
  return <BaseCardView formInfo={formInfo} showCvvTooltip={showCvvTooltip} />
}

export const PlainCardOptions = Object.freeze({
  cardNumberOptions: {
    placeholder: '1234 5678 9012 3456',
    showCardIcon: true,
    schemaOverrides: [
      {
        type: 'mastercard',
        pattern: /^(5[1-5][0-9]{4}|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720|2265)([0-9]{2})/,
        format: /(\d{1,4})/g,
        length: [16],
        cvcLength: [3],
        luhn: true,
      },
    ],
  },
  cardExpiryOptions: {
    placeholder: 'MM / YY',
  },
  cardCvvOptions: {
    placeholder: '123',
    showCardIcon: false,
  },
})

export default PlainCard
