// Initialization for each of our supported field types
export const VGS_FIELD_DETAILS = {
  cardNumber: {
    vgsFieldType: 'card-number',
    validations: ['validCardNumber', 'required'],
    name: 'cardNumber',
    isRequiredMessage: 'Card number is required',
    defaultAutoComplete: 'cc-number',
    showCardIcon: true,
  },
  cardCvv: {
    vgsFieldType: 'card-security-code',
    validations: ['validCardSecurityCode', 'required'],
    name: 'cardCvv',
    isRequiredMessage: 'CVV number is required',
    defaultAutoComplete: 'cc-csc',
  },
  cardExpiry: {
    vgsFieldType: 'card-expiration-date',
    validations: ['validCardExpirationDate', 'required'],
    name: 'cardExpiry',
    isRequiredMessage: 'Expiration date is required',
    defaultAutoComplete: 'cc-exp',
  },
  dummyField: {
    vgsFieldType: 'card-expiration-date',
    validations: [],
    name: 'dummyField',
  },
}
