/*global Cardinal, a*/
/*eslint no-undef: "error"*/
/**
 * Remote authentication takes place when we are authenticating a charge intent with a 3rd party service like
 * 3D secure.  The service is opened in an iFrame and we then show the user whatever is inside the iFrame so
 * that they can do authentication.  Once authentication is complete we will then return an updated charge intent
 * that will allow the user to proceed.  That charge intent could actually contain another authentication url, and
 * we would then show that.
 */
import $ from 'domtastic'
import { addEventListener, makeId } from '../../../common/utils/utils'
import { closePopup, insertAuthIframe } from './RemoteAuthView'
import { log } from '../../../common/utils/logger'
import api from '../../../common/api'
import config from '../../../../config'

/** This class is responsible for handling the flow of doing authentication */
class RemoteAuth {
  /** Show the authentication iFrame to the user and actually start processing the authentication */

  routeAuth = (chargeIntent, authenticationURL) => {
    if (chargeIntent.threeDSecureVersion >= 2) {
      return this.#doAuth3DS2(chargeIntent)
    } else {
      return this.#doAuth(chargeIntent.uuid, authenticationURL)
    }
  }

  #doAuth = (chargeIntentUUID, authenticationURL) => {
    // If a popup is already running, we should kill it before we start a new one
    this.closePopup()
    // Create an identifier that will be used to reference this auth
    this.currentIdentifier = `auth-content-${makeId()}`
    insertAuthIframe(this.currentIdentifier, authenticationURL)
    return this.handleEventsPromise(chargeIntentUUID)
  }

  #doAuth3DS2 = (chargeIntent) => {
    this.closePopup()
    const chargeIntentUUID = chargeIntent.uuid
    return new Promise((resolve, reject) => {
      return this.#checkEnrollment(chargeIntent.uuid)
        .then(
          ({
            chargeIntent,
            acsURL,
            transactionId,
            payload,
            enrollmentStatus,
          }) => {
            return this.#cardinalContinue(
              chargeIntent,
              acsURL,
              transactionId,
              payload,
              enrollmentStatus
            )
          }
        )
        .then(({ chargeIntent, enrollmentStatus }) => {
          if (enrollmentStatus === 'frictionless') {
            resolve(chargeIntent)
          } else {
            return this.#validate(chargeIntentUUID)
          }
        })
        .then((chargeIntent) => {
          resolve(chargeIntent)
        })
        .catch((error) => {
          reject({ failureMessage: error.message })
        })
    })
  }

  /** close this popup and clear the page of anything we added specifically for the popup */
  closePopup = () => {
    closePopup(this.$identifier()?.[0])
    this.currentIdentifier = undefined
  }

  #cardinalContinue = (
    chargeIntent,
    acsUrl,
    authenticationTransactionId,
    payload,
    enrollmentStatus
  ) => {
    return new Promise((resolve, reject) => {
      if (enrollmentStatus === 'frictionless') {
        resolve({
          chargeIntent: chargeIntent,
          enrollmentStatus: enrollmentStatus,
        })
      } else {
        Cardinal.on('payments.validated', function (data, jwt) {
          Cardinal.off('payments.validated')
          resolve({
            enrollmentStatus: enrollmentStatus,
            chargeIntent: chargeIntent,
          })
        })
        Cardinal.continue(
          'cca',
          {
            AcsUrl: acsUrl,
            Payload: payload,
          },
          {
            OrderDetails: {
              TransactionId: authenticationTransactionId,
            },
          }
        )
      }
    })
  }

  #checkEnrollment = (chargeIntentUUID) => {
    return api(
      `${config.thrive.BACKEND_URL}/payments/api/client/v2/authentication/${chargeIntentUUID}/checkEnrollment`,
      {
        method: 'GET',
      }
    ).then((response) => {
      const enrollmentResponse = response?.data
      if (response?.status !== 200 || !enrollmentResponse) {
        log({
          message: 'enrollment_error',
          info:
            response?.message || 'Something went wrong, please contact support',
          data: response?.data,
          status: response?.status,
        })

        throw {
          errorType: 'internal_server_error',
          message: 'Something went wrong, please contact support',
          data: response,
        }
      } else if (
        response?.data?.enrollmentStatus === 'unable_to_authenticate'
      ) {
        throw {
          errorType: 'unable_to_authenticate',
          message:
            response?.message || 'Something went wrong, please contact support',
        }
      } else {
        return enrollmentResponse
      }
    })
  }

  #validate = (chargeIntentUUID) => {
    return api(
      `${config.thrive.BACKEND_URL}/payments/api/client/v2/authentication/${chargeIntentUUID}/validate`,
      {
        method: 'GET',
      }
    ).then((response) => {
      const validationResponse = response?.data
      if (response?.status !== 200 || !validationResponse) {
        log({
          message: 'internal_server_error',
          info:
            response?.message || 'Something went wrong, please contact support',
          status: response?.status,
        })
        throw {
          errorType: 'internal_server_error',
          message: 'Error occurred during authentication validation',
        }
      } else if (response?.data?.state === 'failed') {
        throw {
          errorType: 'validation_error',
          message:
            response?.message || 'Something went wrong, please contact support',
        }
      } else {
        return validationResponse.chargeIntent
      }
    })
  }

  /**
   * Handle the events for auth in an iframe, and return a promise that will contain a result once processing is complete
   *
   * Auth takes place in an iFrame and that iFrame is able to post events up to this parent iFrame.  We listen for
   * those events here so that we can correctly process them and take actions based on those events.
   *
   * We also do a check to make sure that the events we are receiving are the correct events and ignore events that
   * are not connected with the currentIdentifier at the time that we started listening.
   */
  handleEventsPromise = (chargeIntentUUID) =>
    new Promise((resolve, reject) => {
      const currentIdentifier = this.currentIdentifier
      addEventListener((e) => {
        if (currentIdentifier !== this.currentIdentifier) return
        switch (e?.data?.action) {
          case 'SecureForm::chargeReady': {
            log({
              message: 'do_auth_charge_ready',
            })
            let chargeIntent = e.data.data
            this.closePopup()
            resolve(chargeIntent)
            break
          }
          // Handle our user driven popup closes together
          case 'SecureForm::closeAuthPopup':
          case 'SecureForm::closePaymentPopup': {
            log({
              message: 'do_auth_close_popup',
            })
            this.closePopup()
            reject({ failureType: e.data.action })
            break
          }
          // Handle all our error cases together as they should be handled in a similar fashion
          case 'SecureForm::retrieveAuthUrlFailed':
          case 'SecureForm::chargeFailed':
          case 'SecureForm::chargeException':
            {
              const failureMessage = e.data.data
              log({
                message: 'do_auth_error',
                info: failureMessage,
                data: e?.data?.action,
              })
              this.closePopup()
              reject({
                failureType: e.data.action,
                failureMessage: failureMessage,
              })
              console.error('Failure in 3D auth processing:', failureMessage)
            }
            break
          default: {
            log({
              message: 'do_auth_no_action',
              info: 'This event has no action',
              data: e?.data,
            })
            console.warn('This event has no action', e.data.action, e.data)
            break
          }
        }
      }, chargeIntentUUID)
    })

  /** convenience function to get the current identifier */
  $identifier = () => $(`#${this.currentIdentifier}`)
}

/** we want to actually use a singleton version of this class */
const instance = new RemoteAuth()

/** Only want to expose a single method */
export default {
  routeAuth: instance.routeAuth,
}
