import React from 'react'
import './BasicCard.scss'
import BaseCardView from '../../BaseCard/BaseCardView'

const BasicCard = ({ formInfo }) => {
  return (
    <div className="yc-basic-dropin-layout">
      <BaseCardView formInfo={formInfo} />
    </div>
  )
}

export const BasicCardOptions = {
  cardNumberOptions: {
    placeholder: '1234 5678 9012 3456',
    showCardIcon: true,
    schemaOverrides: [
      {
        type: 'mastercard',
        pattern: /^(5[1-5][0-9]{4}|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720|2265)([0-9]{2})/,
        format: /(\d{1,4})/g,
        length: [16],
        cvcLength: [3],
        luhn: true,
      },
    ],
  },
  cardExpiryOptions: {
    placeholder: 'MM / YY',
  },
  cardCvvOptions: {
    placeholder: '123',
    showCardIcon: false,
  },
}

export default BasicCard
