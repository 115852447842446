import * as React from 'react'
import { FormEvent, useState } from 'react'
import CheckoutConfiguration, {
  DisplayMethod,
} from './models/CheckoutConfiguration'
import { PayButton } from './components/PayButton/styled'
import CheckoutIFrame from './components/CheckoutIFrame'

interface Props {
  configuration: CheckoutConfiguration
  startShown?: boolean
}

const Checkout = ({ configuration, startShown = false }: Props) => {
  const [showPopup, setShowPopup] = useState(startShown)

  const onPayNow = (event: FormEvent) => {
    event.preventDefault()

    //Now we can do the work of showing all our fancy doo dabs etc
    setShowPopup(true)
  }

  const onPopupClose = () => {
    setShowPopup(false)
    configuration.onCancel?.()
  }

  /** Called once we have a chargable token to pass to our backend*/
  const onSuccess = (token: any) => {
    setShowPopup(false)
    configuration.callback?.(token)
  }

  return (
    <div>
      {configuration.displayMethod === DisplayMethod.Auto && (
        <PayButton onClick={onPayNow}>
          {configuration.buttonText || 'Pay Now'}
        </PayButton>
      )}
      {showPopup && (
        <CheckoutIFrame
          configuration={configuration}
          onCancel={onPopupClose}
          onSuccess={onSuccess}
        />
      )}
    </div>
  )
}

export default Checkout
