/**
 * In-order to make handling messages easier, this class is used to transform vgs error messages info more sensible error
 * messages so that we can give the user something we believe makes sense when they read it.
 */
class ValidationMessages {
  /** our mappings from vgs messages to what we consider to be better messages*/
  messages = {
    'card-number': {
      'is not a valid card number': 'Your card number is not valid.',
      "the value doesn't match a pattern":
        'You must enter a valid 16 digit card number',
      'is required': 'Your card number can not be empty.',
      'field-message': 'Your card number',
    },
    'card-security-code': {
      'is required': "Your card's security code can not be empty",
      'field-message': "Your card's security code",
    },
    'card-expiration-date': {
      'is not a valid expiration date':
        "Your card's expiration date is invalid",
      'is required': "Your card's expiration date can not be empty.",
      'field-message': "Your card's expiration date",
    },
  }

  /** get a message from the given identifier which will largely be a vgs message itself*/
  message = (fieldType, identifier) => {
    // if we do not find what we are looking for lets just go with the original
    let message = this.messages[fieldType]?.[identifier]
    const fieldMessage = this.messages[fieldType]?.['field-message']
    if (!message && fieldMessage) {
      message = `${fieldMessage} ${identifier}`
    }
    return message || identifier
  }
}

// Create a singleton instance of our class to make life easier
const instance = Object.freeze(new ValidationMessages())
export default instance
