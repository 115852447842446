var api = require("!../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../node_modules/sass-loader/dist/cjs.js!./BaseCardView.scss");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {"injectType":"singletonStyleTag","attributes":{"id":"yc-injected-styles"}};

options.insert = function setup(element) {
                window.__yc__style_injector = function (callback) {
                  if (callback) {
                    callback(element)
                  }
                }
              };
options.singleton = true;

var update = api(content, options);

var exported = content.locals ? content.locals : {};



module.exports = exported;