import ReactDOM from 'react-dom'
import React from 'react'
import CardDropinHandler from './CardDropinHandler'
import { Deferred } from '../../../common/utils/Deferred'
import Events from '../../Events'

/** with drop-in you get the entire form ready made */
export default class CardDropin {
  /** The domElement this field is mounted inside*/
  #domElement
  #flexForm
  #flexFormDeferred = new Deferred()

  constructor(configuration) {
    this.configuration = configuration
  }

  #onFlexLoaded = (flexForm) => {
    this.#flexForm = flexForm
    this.#flexFormDeferred.resolve(flexForm)
  }

  /** Listen for an event on the form associated with this dropin*/
  on = (event, handler) => {
    if (event === Events.READY) {
      this.#flexFormDeferred.promise.then(() => {
        handler(this)
      })
    } else {
      this.#flexFormDeferred.promise.then((flexForm) => {
        flexForm.on(event, handler)
      })
    }
  }

  validationErrorMessage = () => this.#flexForm?.validationErrorMessage() || ''

  isValid = () => this.#flexForm?.isValid() || false

  /**
   * The `DropInForm.mount` method attached your DropInForm to the DOM.  This method accepts
   * either CSS selector (e.g. `#dropin`) or a DOM element.
   */
  mount = (domElement) => {
    // Store our domElement
    if (typeof domElement === 'string' || domElement instanceof String) {
      this.#domElement = document.querySelector(domElement)
    } else {
      this.#domElement = domElement
    }

    // remote the previous mount if there is a previous mount
    ReactDOM.unmountComponentAtNode(this.#domElement)

    /** When this is mounted we will create a card dropin handler and put that inside the component we are mounting in*/
    ReactDOM.render(
      <React.StrictMode>
        <CardDropinHandler
          configuration={this.configuration}
          onFlexLoaded={this.#onFlexLoaded}
        />
      </React.StrictMode>,
      this.#domElement
    )
  }

  /** create a token for authentication purposes*/
  createToken = (extraData = {}) => {
    return this.#flexFormDeferred.promise.then((flexForm) => {
      return flexForm?.createToken(extraData)
    })
  }
}
