const config = {
  // Settings for very good security, override them if we want to...
  vgs: {
    COLLECT_URL:
      process.env.VGS_COLLECT_URL ||
      'https://js.verygoodvault.com/vgs-collect/2.18.0/vgs-collect.js',
    IDENTIFIER: process.env.VGS_IDENTIFIER || 'tntwsgyy4go',
    ENVIRONMENT: process.env.VGS_ENVIRONMENT || 'sandbox',
  },
  thrive: {
    BACKEND_URL: process.env.THRIVE_BACKEND_URL || 'http://localhost:1983',
  },
  cardinal: {
    URL:
      process.env.SONGBIRD_URL ||
      'https://songbirdstag.cardinalcommerce.com/cardinalcruise/v1/songbird.js',
  },
  sdk: {
    // This is needed for the parts of our code that are run via our iFrame (in development this should match your dev machine).
    PUBLIC_URL:
      process.env.SDK_PUBLIC_URL ||
      (process.env.DEPLOY_BASE_URL &&
        `${process.env.DEPLOY_BASE_URL}/sdk/v1/yoco-sdk-web.js`) ||
      'http://localhost:1234/sdk/v1/yoco-sdk-web.js',
  },
  docs: {
    PUBLIC_KEY: process.env.DOCS_PUBLIC_KEY || 'pk_test_8f133808J4bGznOb4c45',
  },
  loggly: {
    KEY: process.env.LOGGLY_KEY || 'beab393e-3fb7-4b97-b44e-c1bbd8efc049',
    PRODUCT_TYPE: process.env.LOGGLY_PRODUCT_TYPE || 'Online SDK v2 DEV',
  },
  firebase: {
    ENDPOINT:
      process.env.FIREBASE_ENDPOINT ||
      'https://intense-torch-9195.firebaseio.com/raven/global/onlineTransactionAlert.json',
  },
  settings: {
    MINIMUM_AMOUNT: 200,
  },
}

export default config
