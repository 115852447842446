import { isSSR } from './utils/utils'
import { log } from './utils/logger'

!isSSR && require('whatwg-fetch')

/** Function that makes calls to core style apis and is able to unpack error messages and success messages*/
const api = (url, params) => {
  return new Promise((resolve) => {
    return fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': params.contentType || 'application/json',
      },
      method: params.method,
      body: params.body,
      mode: params.mode,
    })
      .then((response) => {
        if (response.status < 300 && response.status >= 200) {
          let result = jsonFromResponse(response)

          result.then((result) => {
            log({
              message: 'api_request',
              data: `${response.status}> ${params.method} ${url}`,
              response: result,
            })
          })

          resolve(result)
        } else {
          response.json().then((json) => {
            const message =
              response.message ||
              json.message ||
              json.displayMessage ||
              json.errorMessage ||
              'Something went wrong, please contact support'
            log({
              status: response.status,
              message: message,
            })
            resolve({
              status: response.status,
              message: message,
            })
          })
        }
      })
      .catch((error) => {
        console.warn(`API> Error on ${requestSummary(url, params)}> ${error}`)

        log({
          status: 501,
          message: 'api_error',
          data: `${requestSummary(url, params)}> ${error}`,
        })

        resolve({
          status: 501,
          message: 'Something went wrong, please contact support',
        })
      })
  })
}

/** A summary of the request details*/
const requestSummary = (url, params) => `${params.method} ${url}`

/**
 * Get a json from a response and if you can not create an error message that will be easier to handle
 * as it follows the same format as what would have come back from the server
 */
const jsonFromResponse = (response) => {
  return response
    .json()
    .then((json) => json)
    .catch((error) => ({
      status: 501,
      message:
        'An unexpected error occurred (server returned invalid response)',
      error,
    }))
}

export default api
