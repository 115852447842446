import { injectStyles, isSSR } from '../../../common/utils/utils'
import config from '../../../../config'
import { FLEX_FIELD_CLASS } from '../FlexField'

const $script = isSSR ? {} : require('scriptjs')

/**
 * This is a wrapper around the secure from module from VGS.
 * We load it in an async fashion and then once it is available
 * it can be used*/
class VgsCollectWrapper {
  #vgsPromise

  constructor() {
    this.#vgsPromise = new Promise((resolve) => {
      if (!isSSR) {
        $script(config.vgs.COLLECT_URL, 'vgs')
        $script.ready('vgs', () => {
          resolve(window.VGSCollect)
        })
      }
    })

    this.#vgsPromise.then(() => {
      injectStyles(`
        .${FLEX_FIELD_CLASS} iframe {
          height: 100%;
          width: 100%;
          border: none;
        }
      `)
    })
  }

  /** call this to use the underlying vgs collect object*/
  use(onAvailable) {
    this.#vgsPromise.then(onAvailable)
  }
}

const instance = new VgsCollectWrapper()
export default Object.freeze(instance)
