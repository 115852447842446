import React, { useEffect, useState } from 'react'
import {
  injectWebpackStyles,
  makeId,
  valueWithFallback,
} from '../../../common/utils/utils'
import { log } from '../../../common/utils/logger'

import BasicCard, { BasicCardOptions } from './layouts/BasicCard/BasicCard'
import StyleExtractor from '../../../common/utils/StyleExtractor'
import FieldCard, { FieldCardViewOptions } from './layouts/FieldCard/FieldCard'
import deepMerge from 'deepmerge'
import PlainCard, { PlainCardOptions } from './layouts/PlainCard/PlainCard'
import YocoSDK from '../../YocoSDK'
import $ from 'domtastic'

const CardDropinHandler = ({ configuration, onFlexLoaded }) => {
  /** we want to make sure we have a unique prefix to use for identifying all our related fields*/
  const [idPrefix] = useState(makeId())
  const [flexReady, setFlexReady] = useState(false)
  const [flexFields, setFlexFields] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [flexForm, setFlexForm] = useState()

  // We can based on the style toggle what element is used for the card view
  let [CardViewElement, CardViewElementOptions] = cardViewElementForLayout(
    configuration.layout
  )

  // Should Yoco render a fully working submit button for this form - with animated loader etc
  const showSubmitButton = valueWithFallback(
    configuration?.showSubmitButton,
    CardViewElementOptions?.showSubmitButton
  )

  // Should Yoco handle errors on your behalf for this form
  const showErrors = valueWithFallback(
    configuration?.showErrors,
    CardViewElementOptions?.showErrors
  )

  const submitButtonText = valueWithFallback(
    configuration?.submitButtonText,
    CardViewElementOptions?.submitButtonText
  )

  let styles = configuration.styles

  useEffect(() => {
    // Create an instance of the Yoco SDK and pass it in the public key
    const sdk = new YocoSDK({
      publicKey: configuration.key,
      businessId: configuration.businessId,
    })

    // Create a new instance of our flex form
    const flex = sdk.flex({
      amountInCents: configuration.amountInCents,
      currency: configuration.currency,
      customer: configuration.customer,
      options: { ...configuration },
    })
    setFlexForm(flex)

    log({
      message: `flex_created`,
    })

    // Handle changes to the validity state of the button
    flex.on(YocoSDK.Events.VALIDITY_CHANGE, () => {
      // We want to set our error message to a validation error message
      const errorMessage = flex.validationErrorMessage()
      const showErrorMessage = errorMessage?.trim() && true
      setShowErrorMessage(showErrorMessage)
      showErrorMessage && setErrorMessage(errorMessage)
    })

    // Wire into the submission of this flex form and handle errors related to submission of the flex form
    flex.on('create_token_invoked', (promise) => {
      log({
        message: `create_token_invoked`,
      })

      setShowErrorMessage(false)
      setIsSubmitting(true)
      promise.then((result) => {
        setIsSubmitting(false)
        if (result.error) {
          log({
            message: `create_token_error`,
            data: result.error.message,
          })
          setErrorMessage(result.error.message)
          setShowErrorMessage(true)
        }
      })
    })

    const cardElementStyles = () =>
      styleForElement(
        styles?.cardNumber,
        `#yc-card-number-${idPrefix}`,
        CardViewElementOptions?.cardNumberOptions?.style
      )

    const expiryElementStyles = () =>
      styleForElement(
        styles?.cardExpiry,
        `#yc-card-expiry-${idPrefix}`,
        CardViewElementOptions?.cardExpiryOptions?.style
      )

    const cvvElementStyles = () =>
      styleForElement(
        styles?.cardCvv,
        `#yc-card-cvv-${idPrefix}`,
        CardViewElementOptions?.cardCvvOptions?.style
      )

    const cardNumberField = flex.field('cardNumber', {
      ...(CardViewElementOptions?.cardNumberOptions || {}),
      style: cardElementStyles(),
    })

    const cardExpiryField = flex.field('cardExpiry', {
      ...(CardViewElementOptions?.cardExpiryOptions || {}),
      style: expiryElementStyles(),
    })

    const cardCvvField = flex.field('cardCvv', {
      ...(CardViewElementOptions?.cardCvvOptions || {}),
      style: cvvElementStyles(),
    })

    cardNumberField.mount($(`#yc-card-number-${idPrefix}`)[0])
    cardExpiryField.mount(`#yc-card-expiry-${idPrefix}`)
    cardCvvField.mount(`#yc-card-cvv-${idPrefix}`)

    const onFlexReady = () => {
      injectWebpackStyles()
      const flexReady =
        cardNumberField.state.ready &&
        cardExpiryField.state.ready &&
        cardCvvField.state.ready

      setFlexReady(flexReady)

      if (flexReady) {
        // Update the styles for each of our fields before we show them.
        cardNumberField.updateStyle(cardElementStyles())
        cardExpiryField.updateStyle(expiryElementStyles())
        cardCvvField.updateStyle(cvvElementStyles())
        onFlexLoaded(flex)
      }
    }

    cardNumberField.on('ready', onFlexReady)
    cardExpiryField.on('ready', onFlexReady)
    cardCvvField.on('ready', onFlexReady)

    // By setting up the siblings we can allow one field to flow into another when it has been successfully validated.
    cardNumberField.setNextSibling(cardExpiryField)
    cardExpiryField.setNextSibling(cardCvvField)

    // So that we can choose to listen to events etc if we want to.
    setFlexFields({
      cardNumberField,
      cardExpiryField,
      cardCvvField,
    })
  }, [])

  /** If we have enabled a button on the dropin, we need to handle submission*/
  const onSubmit = () => {
    log({
      message: `token_created`,
    })
    flexForm?.createToken()
  }

  /** All the information that we will need about the form to render the form*/
  const formInfo = {
    idPrefix,
    flexReady,
    flexFields,

    // Error related information
    errorMessage,
    showErrorMessage: showErrorMessage && showErrors,
    showSubmitButton: showSubmitButton,
    submitButtonText,
    isSubmitting,
    onSubmit,
  }

  return <CardViewElement formInfo={formInfo} />
}

const cardViewElementForLayout = (layout) => {
  switch (layout) {
    case 'field':
      return [FieldCard, FieldCardViewOptions]
    case 'basic':
      return [BasicCard, BasicCardOptions]
    case 'plain':
    default:
      return [PlainCard, PlainCardOptions]
  }
}

/**
 * Calculate the style for an element
 *
 * @param elementStyleConfig The passed in style config (this will prevent the extractor being used and is an override).
 * @param elementId The id of the element on the page where we want to stick our extractor element
 * @param layoutStyleOptions Styling from the layout for this field
 */
const styleForElement = (elementStyleConfig, elementId, layoutStyleOptions) => {
  const styleExtractor = new StyleExtractor()

  const elementStyles =
    elementStyleConfig ||
    styleExtractor.extract(elementId, `<input type="text"/>`)

  return deepMerge(elementStyles, layoutStyleOptions || {})
}

export default CardDropinHandler
