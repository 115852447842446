export default class CheckoutConfiguration {
  constructor(
    public publicKey: string,
    public amountInCents: number,
    public currency: string,
    public name?: string,
    public description?: string,
    public image?: string,
    public buttonText?: string,
    public accountId?: string,

    public displayMethod: DisplayMethod = DisplayMethod.Auto,
    public businessId?: string,
    /** string|HTMLElement element used to mount our pay button, if non is provided it will be mounted over the script tag element*/
    public mountElem?: string | HTMLElement,
    public callback?: (token: any) => void,
    public onCancel?: () => void,
    public onClose?: () => void,
    public layout?: string,
    metadata?: Map<string, any>
  ) {}
}

export enum DisplayMethod {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}
