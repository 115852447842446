import styled from 'styled-components'

export const PayButton = styled.button`
  -webkit-box-shadow: 0 2px 5px 0 rgba(50, 50, 93, 0.1),
    0 1px 1px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
  background: #0296cd;
  padding: 4px 32px;
  border: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  line-height: 32px;
  cursor: pointer;
`
