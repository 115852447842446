/**
 * This has a specific use case for writing to the contentDocument allowing for a separated scope
 *
 * There are probably other ways to do this with ShadowDom but this works well for my purposes
 *
 * USAGE:
 *
 * <CustomIframe html={<head><title>Doc Title</title></head><body>Hello React User</body>} />
 *
 */

import React, { Component } from 'react'

export default class CustomIframe extends Component {
  constructor(props) {
    super(props)

    this.iframe_ref = null
    this.onFrameCreated = props.onFrameCreated

    this.writeHTML = this.writeHTML.bind(this)
  }

  writeHTML(frame) {
    setTimeout(() => {
      if (!frame) {
        return
      }

      this.iframe_ref = frame

      let doc = frame.contentDocument

      doc.open()
      doc.write(this.props.html)
      doc.close()

      this.onFrameCreated && this.onFrameCreated(frame)
    }, 1)
  }

  render() {
    return (
      <iframe
        id={this.props.id}
        src="about:blank"
        scrolling="no"
        frameBorder="0"
        ref={this.writeHTML}
      />
    )
  }
}
