import { LogglyTracker } from 'loggly-jslogger'
import { isoTimestamp, makeId } from './utils'
import config from '../../../config'

// check session storage for persisted client identifier
export const getClientIdentifier = () => {
  let clientIdentifier = window.sessionStorage.getItem('ycSdkClientIdentifier')
  if (clientIdentifier === null) {
    clientIdentifier = makeId(32)
    window.sessionStorage.setItem('ycSdkClientIdentifier', clientIdentifier)
  }
  return clientIdentifier
}

const initLog = () => {
  try {
    // instantiate logger
    if (typeof window.loggly === 'undefined')
      window.loggly = new LogglyTracker()

    if (window.loggly) {
      window.clientIdentifier = getClientIdentifier()

      // initiate loggly through initial push with key
      window.loggly.push({
        logglyKey: config.loggly.KEY,
        sendConsoleErrors: true,
        tag: 'loggly-jslogger',
      })
    }
  } catch (e) {
    console.log('Logging error: ' + e)
  }
}

export const log = (data) => {
  try {
    if (window.loggly) {
      window.loggly.push({
        version: 'v2',
        productType: 'Yoco Online SDK v2',
        clientIdentifier: window.clientIdentifier.toString(),
        timestamp: isoTimestamp(),
        logData: data,
      })
    }
  } catch (e) {
    console.log('Logging error: ' + e)
  }
}

initLog()
