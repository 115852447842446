import * as React from 'react'
import {
  CardSchemaBrand,
  PopupCardSchemaHolder,
  PopupHolder,
  PopupInner,
  SafePaymentsPitch,
  DropInForm,
  PayButton,
  PoweredBy,
  ErrorMessage,
  ComponentOuter,
  PopupHolderSpacer,
} from './styled'
import PopupHeaderView from './PopupHeaderView'
import Money from '../../../../common/utils/Money'
// @ts-ignore
import { CSSTransition } from 'react-transition-group'
import Loader from '../../../../common/components/Loader'
import AlertBar from '../../../../common/components/Alert/Alert'

const VisaLogo = require('../images/visa.png')
const MastercardLogo = require('../images/mastercard.png')
const poweredByImage = require('../images/fill-solid.png')
const AmexLogo = require('../images/amex_logo_color.png')
const lock = require('../images/lock.png')

interface Props {
  amount: Money
  dropInId: string
  popupTitle?: string
  popupDescription?: string
  popupImage?: string
  onCancel?: () => void
  errorMessage: string
  showErrorMessage: boolean
  isValid: boolean
  isSubmitting?: boolean
  onSubmit?: () => void
  dropinReady?: boolean
  shake?: boolean
}

const PopupCardView = ({
  amount,
  dropInId,
  popupTitle,
  popupDescription,
  popupImage,
  onCancel,
  errorMessage,
  showErrorMessage,
  isValid,
  isSubmitting = false,
  onSubmit,
  dropinReady = false,
  shake = false,
}: Props) => {
  const showHeader = (popupTitle || popupImage || onCancel) && true

  return (
    <PopupHolderSpacer>
      <PopupHolder dropinReady={dropinReady}>
        <PopupInner shake={shake}>
          {showHeader && (
            <PopupHeaderView
              popupTitle={popupTitle}
              popupImage={popupImage}
              onCancel={onCancel}
            />
          )}
          <ComponentOuter>
            <AlertBar />
            <PopupCardSchemaHolder>
              <CardSchemaBrand>
                <img src={VisaLogo} className="visa" alt="visa" />
              </CardSchemaBrand>
              <CardSchemaBrand>
                <img
                  src={MastercardLogo}
                  className="mastercard"
                  alt="mastercard"
                />
              </CardSchemaBrand>
              <CardSchemaBrand>
                <img src={AmexLogo} className="amex" alt="amex" />
              </CardSchemaBrand>
            </PopupCardSchemaHolder>
            <DropInForm id={dropInId} />
            <CSSTransition
              in={showErrorMessage}
              timeout={10}
              classNames="popup-error-message"
            >
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </CSSTransition>
            <PayButton
              disabled={!isValid}
              onClick={() => !isSubmitting && onSubmit()}
            >
              {isSubmitting ? (
                <Loader />
              ) : (
                <div>
                  <img alt="secure" height="16" src={lock} />{' '}
                  <strong>PAY {amount.prettyPrint()}</strong>
                </div>
              )}
            </PayButton>
            <PoweredBy>
              Secured by&nbsp;
              <img height="35" src={poweredByImage} />
            </PoweredBy>
          </ComponentOuter>
        </PopupInner>
      </PopupHolder>
    </PopupHolderSpacer>
  )
}

export default PopupCardView
