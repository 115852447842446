// @ts-ignore
import $ from 'domtastic'
// @ts-ignore
import FlexForm from './flex/FlexForm/'
// @ts-ignore
import CardDropin from './dropin/CardDropin'
import Events from './Events'
import CheckoutHandler from './checkout/CheckoutHandler'
// @ts-ignore
import config from '../../config'
// @ts-ignore
import { injectWebpackStyles } from '../common/utils/utils'
import CheckoutConfiguration, {
  DisplayMethod,
} from './checkout/models/CheckoutConfiguration'

export default class YocoSDK {
  static Events = Events
  private configuration: CheckoutConfiguration

  constructor(configuration: CheckoutConfiguration) {
    this.configuration = configuration
  }

  /** Create a flex form so that you can custom build your form the way you want to*/
  flex(configuration: CheckoutConfiguration) {
    return new FlexForm({
      ...configuration,
      key: this.configuration.publicKey,
      businessId: this.configuration.businessId || configuration.businessId,
    })
  }

  /** Create a full working form so that you can custom build your form the way you want to*/
  inline(configuration: CheckoutConfiguration) {
    return new CardDropin({
      ...configuration,
      key: this.configuration.publicKey,
      businessId: this.configuration.businessId || configuration.businessId,
    })
  }

  /** Create a popup checkout form that is fully designed and ready to be shown to your user.  Under the surface it will make
   * use of one of our other SDKs to produce its content.*/
  popup(configuration: CheckoutConfiguration) {
    return new CheckoutHandler({
      ...configuration,
      publicKey: this.configuration.publicKey,
      // @ts-ignore
      businessId: this.configuration.businessId || configuration.businessId,
    })
  }

  showPopup(configuration: CheckoutConfiguration) {
    const checkoutHandler = new CheckoutHandler({
      ...configuration,
      publicKey: this.configuration.publicKey,
      // @ts-ignore
      businessId: this.configuration.businessId || configuration.businessId,
      displayMethod: DisplayMethod.Manual,
    })

    const isValidConfiguration = !this.maybeConfigurationError(configuration)
    if (isValidConfiguration) {
      checkoutHandler.showPopup()
    }

    return checkoutHandler
  }

  /** @deprecated please use inline in future*/
  dropin(configuration: CheckoutConfiguration) {
    return this.inline(configuration)
  }

  /** @deprecated please use popup in future*/
  checkout(configuration: CheckoutConfiguration) {
    this.popup(configuration)
  }

  private maybeConfigurationError(configuration: CheckoutConfiguration): any | undefined {
    if (configuration.amountInCents < config.settings.MINIMUM_AMOUNT) {
      const error = {
        error: {
          message: `Amount provided cannot be less than R${(
            config.settings.MINIMUM_AMOUNT / 100
          ).toFixed(2)}`,
        },
      }

      if (configuration.callback && error) {
        configuration.callback(error)
      }
      return error
    }

    return undefined
  }

  static FlexForm = FlexForm
}

export class YocoLegacySDK {
  constructor() {}

  setup(configuration: CheckoutConfiguration) {
    return new YocoSDK(configuration).showPopup(configuration)
  }
}

// @ts-ignore
window.YocoSDK = YocoSDK
// @ts-ignore
window.ThrivePopup = new YocoLegacySDK()

// This has to be done this way to insure that regardless of the framework used it works.
setTimeout(injectWebpackStyles, 100)
$(document).ready(injectWebpackStyles)
