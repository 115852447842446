import { injectStyles } from '../../../common/utils/utils'
import $ from 'domtastic'

/** Create an iframe that will be used to display content when we authenticate*/
export const insertAuthIframe = (identifier, authenticationUrl) => {
  const iFrame = $('body').append(`
      <div id="${identifier}" class="yoco-online-auth-content">
          <iframe src="${authenticationUrl}" allowTransparency="true" ></iframe>
      </div>
    `)

  // We want to wait for the onload before we hide things etc
  setTimeout(() => {
    $(`#${identifier}`).find('iframe')[0].onload = () => {
      // add a class that will hide the main pages scrollbar
      $('html').addClass('yoco-online-auth-visible')
    }
  }, 50)

  return iFrame
}

export const closePopup = (iFrameNode) => {
  if (iFrameNode) {
    iFrameNode.parentNode.removeChild(iFrameNode)
  }
  $('html').removeClass('yoco-online-auth-visible')
}

/** Inject the styles we need to create an authentication iFrame into the page*/
export const injectAuthIframeStyles = () => {
  injectStyles(`
    .yoco-online-auth-content iframe {
      border: none;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      overflow: hidden;
      width: 100%;
      z-index: 2147483647;
    }

    .yoco-online-auth-visible, .yoco-online-auth-visible body {
      overflow: hidden !important;
    }
  `)
}
