import styled, { css, keyframes } from 'styled-components'

export const PopupShake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`
export const PopupHolderSpacer = styled.div`
  margin: auto;
  max-width: 500px;
  padding: 10px;
`

export const PopupHolder = styled.div`
  margin: auto;
  max-width: 480px;
  display: block;
  position: relative;
  z-index: 1;
  transform: scale(1);
  transition: 0.35s cubic-bezier(0.41, 1.44, 0.47, 1);
  opacity: 0;
  color: #061d3f;

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.show-popup-enter-done {
    transform: translateY(100px) !important;
    @media (max-width: 420px) {
      transform: scale(0.9) translateY(1vh) !important;
    }
    opacity: 100;
  }

  ${({ dropinReady }: { dropinReady: boolean }) => {
    if (dropinReady) {
      return `
          transform: translateY(100px) !important;
          @media (max-width: 420px) {
            transform: scale(0.9) translateY(1vh) !important;
          }
          opacity: 100;
      `
    } else {
      return ''
    }
  }}
`

export const PopupInner = styled.div`
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  background: white;
  border: 1px solid transparent;

  ${({ shake }: { shake?: boolean }) =>
    shake
      ? css`
          animation: ${PopupShake} 0.5s;
          animation-iteration-count: infinite;
        `
      : ''}
`

export const PopupHeader = styled.div`
  text-align: left;
  font-size: 22px;
  border-bottom: 1px solid rgba(6, 29, 63, 0.16);
  position: relative;
  padding: 10px 0 0 0;
`

export const PopupHeaderInner = styled.div`
  border-radius: 3px;
  overflow: hidden;
  text-align: center;
  margin: auto;
  display: flex;
  padding: 23px 0 23px 0px;
  max-width: 390px;
`

export const PopupHeaderInfo = styled.div`
  text-align: left;
  ${(props: { showingImage?: boolean }) =>
    props.showingImage && 'padding-left: 10px;'}
`

export const PopupHeaderName = styled.div`
  width: 300px;
  color: #061d3f;
  font-family: 'proxima-nova', sans-serif;
  font-size: 24px;
  line-height: 29px;
`

export const PopupClosingTrigger = styled.div`
  text-align: center;
  position: absolute;
  top: 12px;
  right: 20px;
  padding: 1px;
  cursor: pointer;
  color: #717171;
  width: 15px;
  height: 15px;
  font-size: 40px;
  line-height: 15px;
  font-weight: 200;
  z-index: 100;

  &:after {
    content: '×';
  }
`

export const PopupHeaderImageHolder = styled.div``
export const PopupHeaderImage = styled.div`
  width: 56px;
  height: 56px;
  @media (max-width: 420px) {
    width: 54px;
    height: 54px;
  }
  border-radius: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 20px;
  background-color: #f2f2f2;
  ${(props: { businessImageUrl?: string }) =>
    props.businessImageUrl &&
    `background-image: url(${props.businessImageUrl});`}
`

export const PopupCardSchemaHolder = styled.div`
  flex-direction: row;
  margin: 0 auto;
  display: flex;
  max-width: 390px;
  margin-top: 16px;
  justify-content: flex-end;
`

export const ComponentOuter = styled.div`
  margin: 0 10px;
`

export const SafePaymentsPitch = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #667683;
`

export const CardSchemaBrand = styled.div`
  width: 45px;
  height: 30px;
  margin: 3px;
  border-radius: 4px;
  border: solid 1px #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  & .visa {
    width: 35px;
    height: 20px;
    object-fit: contain;
  }

  & .mastercard {
    width: 36px;
    height: 20px;
    object-fit: contain;
  }

  & .amex {
    width: 48px;
    height: 30px;
    object-fit: contain;
  }
`

/** Styling for the dropin form*/
export const DropInForm = styled.div`
  max-width: 390px;
  margin: auto;

  & .yc-field-group {
    /** styling for the label*/
    & label {
      height: 24px;
      color: black;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      margin: 10px 0p 0px;
    }

    /** regular styling for this input*/
    & .yc-card-input-wrapper {
      height: 35px;
      border-style: solid;
      border-width: 1px;
      border-radius: 3px;
      border-color: rgb(163, 180, 204);
    }

    /** Styling for when this input is focused */
    &.yc-field-group--focused .yc-card-input-wrapper {
      border-color: rgb(0, 148, 204);
      border-width: 2px;
      margin: -1px;
      background-color: rgb(242, 251, 254);
    }

    &.yc-field-group--invalid .yc-card-input-wrapper {
      border-color: rgb(250, 117, 90);
      border-width: 2px;
      margin: -1px;
      background-color: rgba(250, 117, 90, 0.1);
    }
  }
`

export const ErrorMessage = styled.div`
  max-width: 390px;
  margin: auto;
  color: rgb(250, 117, 90);
  margin-top: 0px;
  font-size: 14px;

  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  transition: 0.5s cubic-bezier(0.41, 1.44, 0.47, 1);

  &.popup-error-message-enter-done {
    margin-top: 8px;
    max-height: 60px;
    opacity: 1;
  }
`

export const PayButton = styled.button`
  height: 48px;
  color: #ffffff;
  border-radius: 3px;
  font-family: 'proxima-nova', sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: #37a2e0;
  border-radius: 3px;
  margin: 18px auto 0 auto;
  border-width: 0;
  max-width: 390px;
  width: 100%;
  display: block;
  transition: 0.75 s cubic-bezier(0.41, 1.44, 0.47, 1);
  cursor: pointer;
  outline: none;

  &:disabled {
    color: #ffffff;
    background: #7b8996;
    border: 2px solid #7b8996;
    cursor: inherit;
  }
`

export const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 14px;
`
