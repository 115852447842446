import $ from 'domtastic'

const supportedStyling = [
  // focused on mainly allowing things that relate to text input
  'color',
  'font',
  'font-family',
  'font-size',
  'font-size-adjust',
  'font-stretch',
  'font-style',
  'font-variant',
  'font-variant-alternates',
  'font-variant-caps',
  'font-variant-east-asian',
  'font-variant-ligatures',
  'font-variant-numeric',
  'font-weight',
  'line-height',
  'outline',
  'opacity',
  'text-shadow',
  'text-indent',
  'transition',
  '-moz-osx-font-smoothing',
  '-moz-transition',
  '-webkit-font-smoothing',
  '-webkit-transition',
  '-moz-tap-highlight-color',
  '-webkit-tap-highlight-color',
]

export default class StyleExtractor {
  #computedStyle = (element, pseudoElt) => {
    let styles = window.getComputedStyle(element, pseudoElt)

    styles = supportedStyling.reduce(function (obj, key) {
      if (Object.prototype.hasOwnProperty.call(styles, key))
        obj[key] = styles[key]
      return obj
    }, {})

    return styles
  }

  extract = (parent, elementHtml) => {
    const element = $(elementHtml)
    $(parent).append(element)

    const styles = this.#computedStyle(element[0])
    element.addClass('--yc-input-invalid')
    const invalidStyles = this.#computedStyle(element[0])
    element.removeClass('--yc-input-invalid')

    element.addClass('--yc-placeholder-shown')
    const placeholderStyles = this.#computedStyle(
      element[0],
      ':placeholder-shown'
    )
    element.removeClass('--yc-placeholder-shown')

    element.remove()
    return {
      base: {
        ...styles,
        // We do our best in this case with the placeholder
        '&::placeholder': placeholderStyles || {
          opacity: 0.3,
        },
      },
      invalid: invalidStyles || {},
    }
  }
}
