/*global Cardinal, a*/
/*eslint no-undef: "error"*/

/**
 * This is our wrapper around vgs form library that remaps their interface into our own.  We have chosen to do this because
 * we feel it is better to define our own interface instead of using theirs and effectively replicating their documentation and
 * potentially locking ourselves into some of their functionality.  This also allows us to compensate for some of the issues
 * we find code wise without needing support form them.
 */
import vgs from '../vgs/VgsCollectWrapper'
import config from '../../../../config'
import api from '../../../common/api'
import { log } from '../../../common/utils/logger'
import FlexField from '../FlexField'
import { injectAuthIframeStyles } from '../auth/RemoteAuthView'
import CreateTokenFlow from './flow/CreateTokenFlow'
import Events from '../../Events'
export default class FlexForm {
  #chargeIntentResponse
  #vgsSecureForm
  #nameToFieldMap = {}
  #wasSubmitted = false
  #isValid = false
  #currentState
  #trueFocus
  #createTokenPromise
  /** All of our event handlers for the events that we allow people to listen on*/
  #onEventHandlers = {}

  /** This is to allow for some global event listening, and not purely local event listening*/
  static #globalOnEventHandlers = {}

  constructor(configuration) {
    this.configuration = configuration

    // transform variables to match the api - long term the API will directly take on this format
    this.configuration.businessId = configuration.businessId
    this.configuration.email = configuration?.customer?.email
    this.configuration.phone = configuration?.customer?.phone
    this.configuration.firstName = configuration?.customer?.firstName
    this.configuration.lastName = configuration?.customer?.lastName
    this.configuration.sdkVersion = process.env.npm_package_version

    this.validationErrorMessage()
    /** Create a charge intent that reflects our intention to charge the customer*/
    this.#chargeIntentResponse = api(
      `${config.thrive.BACKEND_URL}/payments/api/internal/v1/charge_intents/`,
      {
        method: 'POST',
        body: JSON.stringify(configuration),
      }
    ).then((json) => {
      if (json.data) {
        return json.data
      } else {
        if (json.message) {
          log({
            message: json.message,
          })
          throw new Error(json.message)
        } else {
          log({
            message: 'Something went wrong POSTing charge_intent',
          })
          throw new Error('Something went wrong, please contact support')
        }
      }
    })

    this.#vgsSecureForm = new Promise((resolve) => {
      vgs.use((vgs) =>
        resolve(
          vgs.create(
            config.vgs.IDENTIFIER,
            config.vgs.ENVIRONMENT,
            this.#onVgsFormStateUpdate
          )
        )
      )
    })

    this.#vgsSecureForm.then(() => {
      // We need to setup the styles for our authentication popup iframe
      injectAuthIframeStyles()
    })
  }

  /** Function called when a user wants to create a field on this flex form*/
  field(fieldType, options) {
    return new FlexField(
      fieldType,
      this.#vgsSecureForm,
      options,
      (fieldName, mountedFieldInfo) => {
        // A field was just mounted on the vgs side, we want to store that info
        this.#nameToFieldMap[fieldName] = mountedFieldInfo

        const [field] = mountedFieldInfo
        // We also want to attach some listeners
        field.on(Events.FOCUS, () => this.#handleFocusChange())
        field.on(Events.BLUR, () => this.#handleFocusChange())
        field.on(Events.VALIDITY_CHANGE, () => this.#handleValidityChange())
      }
    )
  }

  /** We need to deal with focus mapping because of a bug related to mobile devices, so this is a hardening work around.*/
  #calculateTrueFocus = (state, trueFocus) => {
    let someFieldFocused = false
    for (const [fieldName, fieldState] of Object.entries(state)) {
      if (fieldState.isFocused) {
        someFieldFocused = true
        if (trueFocus !== fieldName && trueFocus) {
          state[trueFocus].isFocused = false
          trueFocus = fieldName
        } else if (!trueFocus) {
          trueFocus = fieldName
        }
      }
    }
    if (!someFieldFocused) trueFocus = undefined

    return trueFocus
  }

  /** Listener function for each time that we get an update event on a vgs form*/
  #onVgsFormStateUpdate = (state) => {
    this.#currentState = state
    this.#trueFocus = this.#calculateTrueFocus(state, this.#trueFocus)

    this.#isValid = true

    for (const [fieldName] of Object.entries(state)) {
      const fieldElement = document.getElementById(`${fieldName}`)
      const [flexField, updateFlexFieldState] = this.#nameToFieldMap[fieldName]

      const fieldState = state[fieldName]

      if (!fieldState?.isValid) {
        this.#isValid = false
      }

      if (updateFlexFieldState) {
        updateFlexFieldState(fieldState, this.#wasSubmitted)
      }

      if (fieldName === this.#trueFocus) {
        fieldElement?.classList?.add('isFocused')
      } else {
        fieldElement?.classList?.remove('isFocused')
      }

      if (fieldName === this.#trueFocus && fieldState?.isValid) {
        flexField.focusNext()
        flexField.setNextSibling(undefined)
      }
    }
  }

  /** create a chargeable token from the card data the user has entered in the fields related to card payment*/
  createToken = (extraData = {}) => {
    const validateConfigurationResult = this.#validateConfiguration(
      this.configuration
    )
    if (validateConfigurationResult) {
      return Promise.resolve(validateConfigurationResult)
    }

    if (this.#wasSubmitted) {
      return Promise.resolve({
        error: {
          message:
            'The transaction has begun processing, please wait for it to complete.',
        },
      })
    }
    this.#wasSubmitted = true
    const promise = Promise.all([
      this.#chargeIntentResponse,
      this.#vgsSecureForm,
    ])
      .then(([createChargeIntentResponse, vgsSecureForm]) => {
        const createTokenFlow = new CreateTokenFlow(
          this.configuration.key,
          createChargeIntentResponse.uuid,
          createChargeIntentResponse.threeDSecureVersion,
          vgsSecureForm,
          this.#currentState?.cardNumber?.cardType,
          this.#currentState?.cardNumber?.last4,
          extraData
        )
        const flow = createTokenFlow.promise()

        //reset wasSubmitted after our create flow
        flow
          .then(() => {
            this.#wasSubmitted = false
            return true
          })
          .catch(() => {
            this.#wasSubmitted = false
          })

        //Save the promise so we can return it
        this.#createTokenPromise = flow
        return flow
      })
      .catch((error) => {
        this.#wasSubmitted = false
        // we have some errors we now need to handle
        this.#wasSubmitted = false
        switch (error.errorType) {
          case 'vgs_tokenize_error':
            // deal with a vgs tokenization error that has come back with field related data
            this.#onVgsFormStateUpdate(error.fieldErrors)

            return {
              error: {
                message: this.validationErrorMessage() || error.message,
              },
            }
          default:
            log({
              message: error.message,
              data: error.data,
            })

            // deal with a standard error occurring while we are attempting to process a payment
            return { error: { message: error.message, data: error.data } }
        }
      })

    this.#handleCreateTokenInvoked(promise)

    return promise
  }

  validationErrorMessage = () => {
    // We need to get the most relevant error message from our fields.....
    const cardNumberError = this.#cardNumberField()?.errorMessage()
    const cardExpiryError = this.#cardExpiryField()?.errorMessage()
    const cardCvvError = this.#cardCvvField()?.errorMessage()
    return cardNumberError || cardExpiryError || cardCvvError || ''
  }

  /** is this form currently in a valid state*/
  isValid = () => {
    let fields = Object.values(this.#nameToFieldMap).map(([field]) => field)
    let isValid = fields.length > 0
    fields.forEach((field) => {
      isValid = field.state?.complete && isValid
    })
    return isValid
  }

  #cardNumberField = () => this.#nameToFieldMap['cardNumber']?.[0]
  #cardExpiryField = () => this.#nameToFieldMap['cardExpiry']?.[0]
  #cardCvvField = () => this.#nameToFieldMap['cardCvv']?.[0]

  /** Add a handler to listen for a specific event at the form level*/
  on = (event, handler) => {
    if (!this.#onEventHandlers[event]) {
      this.#onEventHandlers[event] = []
    }

    this.#onEventHandlers[event].push(handler)
  }

  /** Add a handler to listen for a specific event at the form level globally */
  static on = (event, handler) => {
    if (!FlexForm.#globalOnEventHandlers[event]) {
      FlexForm.#globalOnEventHandlers[event] = []
    }

    FlexForm.#globalOnEventHandlers[event].push(handler)
  }

  #handler = (eventName) => {
    return [].concat(
      this.#onEventHandlers[eventName] || [],
      FlexForm.#globalOnEventHandlers[eventName] || []
    )
  }

  /** One of our fields has either gained or lost focus*/
  #handleFocusChange = () => {
    let focus = false
    Object.values(this.#nameToFieldMap).forEach(([field]) => {
      focus = field.state.focus || focus
    })

    const handlers = this.#handler(focus ? Events.FOCUS : Events.BLUR)
    handlers?.forEach((handler) => handler(this))
  }

  /** Our form has changed from being complete to incomplete or vice versa*/
  #handleValidityChange = () => {
    const handlers = this.#handler(Events.VALIDITY_CHANGE)
    handlers?.forEach((handler) => handler(this))
  }

  /**
   * When we invoke create token we allow anyone to listen for the results of create token.  This is designed more as
   * for private internal usage, but in some advanced situations the user could tie into this, but it will not be
   * documented at this stage.
   *
   * We will give the promise for create token to the handler.
   */
  #handleCreateTokenInvoked = (promise) => {
    const handlers = this.#handler('create_token_invoked')
    handlers?.forEach((handler) => handler(promise))
    this.#handleCardTokenized(promise)
  }

  /** This is invoked when a card is tokenized and we want to now invoke the listeners */
  #handleCardTokenized = (promise) => {
    promise.then((result) => {
      if (!result.error) {
        const handlers = this.#handler(Events.CARD_TOKENIZED)
        handlers?.forEach((handler) => handler(result))
      }
    })
  }

  #validateConfiguration = (configuration) => {
    if (configuration.amountInCents < config.settings.MINIMUM_AMOUNT) {
      return {
        error: {
          message: `Amount provided cannot be less than R${(
            config.settings.MINIMUM_AMOUNT / 100
          ).toFixed(2)}`,
        },
      }
    }
  }
}
